var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M5.00002 21.5546C4.99928 21.64 5.02299 21.7238 5.06831 21.7958C5.11362 21.8679 5.17859 21.9252 5.25538 21.9607C5.33217 21.9963 5.41749 22.0086 5.50104 21.9962C5.5846 21.9838 5.66282 21.9472 5.72627 21.8908L11.7113 16.5821C11.7902 16.5094 11.8932 16.4691 12 16.4691C12.1068 16.4691 12.2098 16.5094 12.2887 16.5821L18.2737 21.8908C18.3372 21.9472 18.4154 21.9838 18.499 21.9962C18.5825 22.0086 18.6678 21.9963 18.7446 21.9607C18.8214 21.9252 18.8864 21.8679 18.9317 21.7958C18.977 21.7238 19.0007 21.64 19 21.5546V6.86719H5.00002V21.5546Z",
          fill: "#F46414",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M16.375 2H7.62499C6.9288 2 6.26112 2.27965 5.76884 2.77744C5.27656 3.27523 5 3.95037 5 4.65435V5.53914H19V4.65435C19 3.95037 18.7234 3.27523 18.2311 2.77744C17.7388 2.27965 17.0712 2 16.375 2Z",
          fill: "#F46414",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }