var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "21",
        viewBox: "0 0 20 21",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M16.25 6.33301L10 12.2854L3.75 6.33301L2.5 7.52348L8.75 13.4759L10 14.6663L11.25 13.4759L17.5 7.52348L16.25 6.33301Z",
          fill: "#636262",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }