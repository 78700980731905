var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "14",
        viewBox: "0 0 14 14",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.91602 6.99935H11.0827M11.0827 6.99935L6.99935 2.91602M11.0827 6.99935L6.99935 11.0827",
          stroke: "white",
          "stroke-width": "1.2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }