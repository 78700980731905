var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "17",
        viewBox: "0 0 16 17",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M12.0007 2.5C11.2643 2.5 10.6673 3.09695 10.6673 3.83333C10.6673 4.06487 10.7263 4.28262 10.8301 4.47237C10.8369 4.48266 10.8435 4.4932 10.8498 4.50398C10.856 4.51468 10.8619 4.52549 10.8675 4.53638C11.1028 4.91477 11.5223 5.16667 12.0007 5.16667C12.737 5.16667 13.334 4.56971 13.334 3.83333C13.334 3.09695 12.737 2.5 12.0007 2.5ZM10.106 5.7099C10.5893 6.19779 11.2597 6.5 12.0007 6.5C13.4734 6.5 14.6673 5.30609 14.6673 3.83333C14.6673 2.36058 13.4734 1.16667 12.0007 1.16667C10.5279 1.16667 9.33398 2.36058 9.33398 3.83333C9.33398 4.08468 9.36876 4.3279 9.43376 4.55846L5.89528 6.62343C5.41201 6.13555 4.74162 5.83333 4.00065 5.83333C2.52789 5.83333 1.33398 7.02724 1.33398 8.5C1.33398 9.97276 2.52789 11.1667 4.00065 11.1667C4.74176 11.1667 5.41226 10.8643 5.89555 10.3763L9.43459 12.4386C9.36906 12.67 9.33398 12.9142 9.33398 13.1667C9.33398 14.6394 10.5279 15.8333 12.0007 15.8333C13.4734 15.8333 14.6673 14.6394 14.6673 13.1667C14.6673 11.6939 13.4734 10.5 12.0007 10.5C11.2608 10.5 10.5913 10.8013 10.1082 11.2879L6.56765 9.22475C6.63258 8.99431 6.66732 8.75121 6.66732 8.5C6.66732 8.24866 6.63254 8.00543 6.56755 7.77488L10.106 5.7099ZM5.13378 7.79696C5.13937 7.80785 5.14528 7.81865 5.15153 7.82935C5.15782 7.84013 5.16436 7.85067 5.17115 7.86096C5.27497 8.05071 5.33398 8.26846 5.33398 8.5C5.33398 8.73154 5.27497 8.94929 5.17116 9.13903C5.16429 9.14944 5.15767 9.16011 5.15131 9.17102C5.14515 9.1816 5.1393 9.19228 5.13378 9.20305C4.89852 9.58143 4.479 9.83333 4.00065 9.83333C3.26427 9.83333 2.66732 9.23638 2.66732 8.5C2.66732 7.76362 3.26427 7.16667 4.00065 7.16667C4.479 7.16667 4.89852 7.41857 5.13378 7.79696ZM10.8108 12.5643C10.8273 12.5426 10.8426 12.5198 10.8567 12.4957C10.8701 12.4725 10.882 12.449 10.8924 12.425C11.1317 12.0682 11.5388 11.8333 12.0007 11.8333C12.737 11.8333 13.334 12.4303 13.334 13.1667C13.334 13.903 12.737 14.5 12.0007 14.5C11.2643 14.5 10.6673 13.903 10.6673 13.1667C10.6673 12.9499 10.719 12.7452 10.8108 12.5643Z",
          fill: "#636262",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }