var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_11290_13410)" } }, [
        _c("path", {
          attrs: {
            d: "M23.0977 20.9873C23.259 21.5862 23.0943 22.2057 22.655 22.6451C22.4429 22.8596 22.1776 23.0138 21.8863 23.0919C21.595 23.17 21.2881 23.1692 20.9972 23.0895L18.1553 22.3284C16.3044 23.4265 14.1912 24.004 12.0392 23.9999C8.92357 23.9999 5.82429 22.8047 3.51785 20.4991C2.32397 19.3045 1.39518 17.8718 0.791981 16.2944C0.188784 14.7169 -0.0752792 13.03 0.0169964 11.3437C0.20491 7.93978 1.85151 4.75469 4.53635 2.60527C8.91242 -0.898151 15.2423 -0.864687 19.5866 2.68422C24.2243 6.47594 25.3724 13.0606 22.3366 18.1472L23.0977 20.9873ZM18.5011 4.01334C14.7806 0.972402 9.3586 0.943228 5.60978 3.94469C3.27159 5.81696 1.89441 8.47778 1.73138 11.4389C1.65262 12.8841 1.87911 14.3297 2.3961 15.6815C2.91309 17.0333 3.709 18.2611 4.73199 19.2849C8.12644 22.6785 13.5202 23.2663 17.5573 20.6818C17.6577 20.6175 17.7705 20.5748 17.8884 20.5565C18.0063 20.5381 18.1268 20.5446 18.242 20.5754L21.4425 21.4326L20.5845 18.2338C20.5536 18.1186 20.5472 17.9981 20.5655 17.8802C20.5838 17.7623 20.6265 17.6496 20.6909 17.5491C23.5062 13.1542 22.5649 7.33571 18.5011 4.01334Z",
            fill: "#636262",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M8.98978 9.87548C9.21735 9.87548 9.4356 9.78508 9.59651 9.62416C9.75743 9.46324 9.84783 9.245 9.84783 9.01743C9.84783 8.35673 10.1464 7.73893 10.6673 7.32363C11.1941 6.90319 11.8565 6.75903 12.5327 6.91434C12.9166 7.00746 13.2674 7.20455 13.5467 7.48396C13.826 7.76338 14.0229 8.11434 14.1158 8.4983C14.3243 9.40441 13.9665 10.3174 13.2037 10.8236C11.92 11.6748 11.1538 13.1498 11.1538 14.7707C11.1538 14.9982 11.2442 15.2165 11.4051 15.3774C11.566 15.5383 11.7843 15.6287 12.0118 15.6287C12.2394 15.6287 12.4577 15.5383 12.6186 15.3774C12.7795 15.2165 12.8699 14.9982 12.8699 14.7707C12.8699 13.7273 13.3495 12.786 14.1527 12.254C15.517 11.3488 16.1588 9.72274 15.7881 8.1139C15.6249 7.41528 15.2696 6.77619 14.7625 6.26873C14.2553 5.76128 13.6165 5.40566 12.9179 5.242C12.3447 5.10612 11.7482 5.102 11.1732 5.22997C10.5981 5.35793 10.0596 5.61464 9.59814 5.98078C9.13967 6.34306 8.76941 6.80467 8.51525 7.33083C8.26109 7.85698 8.12966 8.43396 8.13087 9.01828C8.13087 9.24585 8.22127 9.4641 8.38219 9.62502C8.5431 9.78593 8.76135 9.87634 8.98892 9.87634L8.98978 9.87548ZM12.029 17.15H12.0204C11.5468 17.15 11.1667 17.5344 11.1667 18.0081C11.1667 18.4817 11.5554 18.8661 12.029 18.8661C12.2566 18.8661 12.4748 18.7757 12.6357 18.6148C12.7966 18.4539 12.887 18.2357 12.887 18.0081C12.887 17.7805 12.7966 17.5623 12.6357 17.4014C12.4748 17.2404 12.2566 17.15 12.029 17.15Z",
            fill: "#636262",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_11290_13410" } }, [
          _c("rect", { attrs: { width: "24", height: "24", fill: "white" } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }