var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "14",
        height: "15",
        viewBox: "0 0 14 15",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2.91667 12.75H11.0833C11.7277 12.75 12.25 12.2277 12.25 11.5833V3.41667C12.25 2.77233 11.7277 2.25 11.0833 2.25H2.91667C2.27233 2.25 1.75 2.77233 1.75 3.41667V11.5833C1.75 12.2277 2.27233 12.75 2.91667 12.75ZM2.91667 12.75L9.33333 6.33333L12.25 9.25M5.83333 5.45833C5.83333 5.94158 5.44158 6.33333 4.95833 6.33333C4.47508 6.33333 4.08333 5.94158 4.08333 5.45833C4.08333 4.97508 4.47508 4.58333 4.95833 4.58333C5.44158 4.58333 5.83333 4.97508 5.83333 5.45833Z",
          stroke: "#0960BD",
          "stroke-width": "1.16667",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }