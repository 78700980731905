var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M13.3759 2.60841C13.1837 2.41555 12.9554 2.26254 12.704 2.15814C12.4527 2.05374 12.1832 2 11.9111 2C11.639 2 11.3695 2.05374 11.1182 2.15814C10.8668 2.26254 10.6385 2.41555 10.4463 2.60841L9.95935 3.09595L2.13615 10.9372L1 15L5.05768 13.8624L12.889 6.06181L13.3759 5.57427C13.5734 5.38125 13.7304 5.15062 13.8376 4.89594C13.9448 4.64127 14 4.3677 14 4.09134C14 3.81498 13.9448 3.54141 13.8376 3.28674C13.7304 3.03206 13.5734 2.80143 13.3759 2.60841ZM4.5 13L3 11.5L11.1464 3.35355C11.3417 3.15829 11.6583 3.15829 11.8536 3.35355L12.6464 4.14645C12.8417 4.34171 12.8417 4.65829 12.6464 4.85355L4.5 13ZM19 17H1V18H19V17ZM7 14H19V15H7V14Z",
          fill: "#525252",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }