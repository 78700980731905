var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "21",
        viewBox: "0 0 20 21",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10 3L1.89566 13.0112C1.73688 13.2074 1.87648 13.5 2.12884 13.5H6.90909V17.7C6.90909 17.8657 7.04341 18 7.20909 18H12.7909C12.9566 18 13.0909 17.8657 13.0909 17.7V13.5H17.8712C18.1235 13.5 18.2631 13.2074 18.1043 13.0112L10 3ZM11.9318 12.375V16.875H8.06818V12.375H3.81818L10 4.8587L16.1818 12.375H11.9318Z",
          fill: "#0960BD",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }