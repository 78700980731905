var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "16",
        height: "16",
        viewBox: "0 0 16 16",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M6.66732 2.00033C6.49051 2.00033 6.32094 2.07056 6.19591 2.19559C6.07089 2.32061 6.00065 2.49018 6.00065 2.66699V3.33366H10.0007V2.66699C10.0007 2.49018 9.93041 2.32061 9.80539 2.19559C9.68036 2.07056 9.5108 2.00033 9.33398 2.00033H6.66732ZM11.334 3.33366V2.66699C11.334 2.13656 11.1233 1.62785 10.7482 1.25278C10.3731 0.877706 9.86442 0.666992 9.33398 0.666992H6.66732C6.13688 0.666992 5.62818 0.877706 5.2531 1.25278C4.87803 1.62785 4.66732 2.13656 4.66732 2.66699V3.33366H2.00065C1.63246 3.33366 1.33398 3.63214 1.33398 4.00033C1.33398 4.36852 1.63246 4.66699 2.00065 4.66699H2.66732V13.3337C2.66732 13.8641 2.87803 14.3728 3.2531 14.7479C3.62818 15.1229 4.13688 15.3337 4.66732 15.3337H11.334C11.8644 15.3337 12.3731 15.1229 12.7482 14.7479C13.1233 14.3728 13.334 13.8641 13.334 13.3337V4.66699H14.0007C14.3688 4.66699 14.6673 4.36852 14.6673 4.00033C14.6673 3.63214 14.3688 3.33366 14.0007 3.33366H11.334ZM4.00065 4.66699V13.3337C4.00065 13.5105 4.07089 13.68 4.19591 13.8051C4.32094 13.9301 4.49051 14.0003 4.66732 14.0003H11.334C11.5108 14.0003 11.6804 13.9301 11.8054 13.8051C11.9304 13.68 12.0007 13.5105 12.0007 13.3337V4.66699H4.00065ZM6.66732 6.66699C7.03551 6.66699 7.33398 6.96547 7.33398 7.33366V11.3337C7.33398 11.7018 7.03551 12.0003 6.66732 12.0003C6.29913 12.0003 6.00065 11.7018 6.00065 11.3337V7.33366C6.00065 6.96547 6.29913 6.66699 6.66732 6.66699ZM8.66732 7.33366C8.66732 6.96547 8.96579 6.66699 9.33398 6.66699C9.70218 6.66699 10.0007 6.96547 10.0007 7.33366V11.3337C10.0007 11.7018 9.70218 12.0003 9.33398 12.0003C8.96579 12.0003 8.66732 11.7018 8.66732 11.3337V7.33366Z",
          fill: "#8D92AA",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }