var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M16.375 2H7.62501C6.92882 2 6.26114 2.27965 5.76886 2.77744C5.27658 3.27523 5.00002 3.95037 5.00002 4.65435V21.5537C4.99928 21.6391 5.02299 21.7229 5.06831 21.795C5.11362 21.867 5.17859 21.9243 5.25538 21.9598C5.33217 21.9954 5.41749 22.0077 5.50104 21.9953C5.5846 21.9829 5.66282 21.9463 5.72627 21.8899L11.7113 16.5812C11.7902 16.5085 11.8932 16.4682 12 16.4682C12.1068 16.4682 12.2098 16.5085 12.2887 16.5812L18.2737 21.8899C18.3372 21.9463 18.4154 21.9829 18.499 21.9953C18.5825 22.0077 18.6678 21.9954 18.7446 21.9598C18.8214 21.9243 18.8864 21.867 18.9317 21.795C18.977 21.7229 19.0007 21.6391 19 21.5537V4.65435C19 3.95037 18.7234 3.27523 18.2311 2.77744C17.7389 2.27965 17.0712 2 16.375 2ZM17.6875 19.5984L12.2887 14.7763C12.2098 14.7035 12.1068 14.6632 12 14.6632C11.8932 14.6632 11.7902 14.7035 11.7113 14.7763L6.31251 19.5984V6.86631H17.6875V19.5984ZM6.31251 5.53914V4.65435C6.31705 4.30379 6.45678 3.96887 6.70195 3.72097C6.94711 3.47306 7.27832 3.33176 7.62501 3.32718H16.375C16.7217 3.33176 17.0529 3.47306 17.2981 3.72097C17.5432 3.96887 17.683 4.30379 17.6875 4.65435V5.53914H6.31251Z",
          fill: "#525252",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }