var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M10.6937 1.66666L9.3048 1.66666L9.30479 9.30544H1.66602L1.66602 10.6943H9.3048V18.3333H10.6937L10.6937 10.6943H18.3327V9.30544H10.6937V1.66666Z",
          fill: "#FEFEFF",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }