var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "386",
        height: "360",
        viewBox: "0 0 386 360",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_10765_97988)" } }, [
        _c("path", {
          attrs: {
            d: "M56.2046 100.609L57.8246 103.879L61.4346 104.409L58.8246 106.959L59.4446 110.559L56.2046 108.859L52.9746 110.559L53.5946 106.959L50.9746 104.409L54.5946 103.879L56.2046 100.609Z",
            fill: "#8D92AA",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M379.855 254.84L381.475 258.12L385.095 258.65L382.475 261.2L383.095 264.8L379.855 263.1L376.625 264.8L377.245 261.2L374.625 258.65L378.245 258.12L379.855 254.84Z",
            fill: "#CDD6DF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M315.755 349.93L317.365 353.2L320.985 353.73L318.365 356.28L318.985 359.88L315.755 358.18L312.515 359.88L313.135 356.28L310.515 353.73L314.135 353.2L315.755 349.93Z",
            fill: "#CDD6DF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M169.095 42.5102L169.695 43.7302L171.035 43.9202C171.375 43.9702 171.505 44.3802 171.265 44.6202L170.295 45.5702L170.525 46.9102C170.585 47.2402 170.235 47.5002 169.935 47.3402L168.735 46.7102L167.535 47.3402C167.235 47.5002 166.885 47.2402 166.945 46.9102L167.175 45.5702L166.205 44.6202C165.965 44.3802 166.095 43.9702 166.435 43.9202L167.775 43.7302L168.375 42.5102C168.525 42.2102 168.955 42.2102 169.105 42.5102H169.095Z",
            fill: "#8D92AA",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M3.925 334.37L4.525 335.59L5.865 335.78C6.205 335.83 6.335 336.24 6.095 336.48L5.125 337.43L5.355 338.77C5.415 339.1 5.065 339.36 4.765 339.2L3.565 338.57L2.365 339.2C2.065 339.36 1.715 339.1 1.775 338.77L2.005 337.43L1.035 336.48C0.794995 336.24 0.924995 335.83 1.265 335.78L2.605 335.59L3.205 334.37C3.355 334.07 3.785 334.07 3.935 334.37H3.925Z",
            fill: "#CDD6DF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M30.9055 29.6596L31.5055 30.8796L32.8455 31.0696C33.1755 31.1196 33.3155 31.5296 33.0755 31.7696L32.1055 32.7196L32.3355 34.0596C32.3955 34.3896 32.0455 34.6496 31.7455 34.4896L30.5455 33.8596L29.3455 34.4896C29.0455 34.6496 28.6955 34.3896 28.7555 34.0596L28.9855 32.7196L28.0155 31.7696C27.7755 31.5296 27.9055 31.1196 28.2455 31.0696L29.5855 30.8796L30.1855 29.6596C30.3355 29.3596 30.7655 29.3596 30.9155 29.6596H30.9055Z",
            fill: "#8D92AA",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M29.285 270.68C29.285 271.41 28.695 272.01 27.955 272.01C27.215 272.01 26.625 271.42 26.625 270.68C26.625 269.94 27.215 269.35 27.955 269.35C28.695 269.35 29.285 269.94 29.285 270.68Z",
            fill: "#8D92AA",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M218.825 104.25C218.825 104.98 218.235 105.58 217.495 105.58C216.755 105.58 216.165 104.99 216.165 104.25C216.165 103.51 216.755 102.92 217.495 102.92C218.235 102.92 218.825 103.51 218.825 104.25Z",
            fill: "#8D92AA",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M149.865 30.7597C149.865 31.4897 149.275 32.0897 148.535 32.0897C147.795 32.0897 147.205 31.4997 147.205 30.7597C147.205 30.0197 147.795 29.4297 148.535 29.4297C149.275 29.4297 149.865 30.0197 149.865 30.7597Z",
            fill: "#CDD6DF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M151.968 354.898C155.918 353.965 158.365 350.007 157.433 346.056C156.5 342.105 152.542 339.658 148.591 340.591C144.64 341.523 142.193 345.482 143.126 349.433C144.058 353.383 148.017 355.83 151.968 354.898Z",
            fill: "#F1F2F3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M147.755 343.449C146.195 343.449 144.745 343.949 143.565 344.809C143.175 345.709 142.955 346.699 142.955 347.739C142.955 351.799 146.245 355.079 150.305 355.079C151.675 355.079 152.965 354.699 154.065 354.039C154.065 354.039 154.065 354.036 154.065 354.029C154.615 353.009 154.925 351.849 154.925 350.609C154.925 346.659 151.715 343.449 147.755 343.449Z",
            fill: "#F1F2F3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M90.8346 220.01H29.9746V192.54L90.8246 120.2H119.945V194.1H135.035V220.02H119.945V242.49H90.8246V220.02L90.8346 220.01ZM90.8346 194.09V156.25L58.6746 194.09H90.8346Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M145.455 181.87C145.455 159.02 149.565 143.02 157.795 133.89C166.025 124.76 178.555 120.19 195.395 120.19C203.485 120.19 210.125 121.19 215.325 123.18C220.515 125.18 224.755 127.77 228.035 130.97C231.315 134.17 233.895 137.53 235.785 141.06C237.675 144.59 239.185 148.7 240.335 153.4C242.575 162.37 243.695 171.72 243.695 181.45C243.695 203.27 240.005 219.23 232.625 229.35C225.245 239.47 212.535 244.52 194.485 244.52C184.365 244.52 176.195 242.91 169.965 239.68C163.735 236.45 158.615 231.73 154.625 225.49C151.725 221.06 149.475 215.01 147.855 207.32C146.245 199.64 145.435 191.15 145.435 181.85L145.455 181.87ZM178.585 181.95C178.585 197.26 179.935 207.72 182.645 213.32C185.355 218.92 189.275 221.73 194.415 221.73C197.805 221.73 200.745 220.54 203.235 218.16C205.725 215.78 207.555 212.02 208.735 206.88C209.915 201.74 210.495 193.73 210.495 182.85C210.495 166.89 209.145 156.16 206.435 150.66C203.725 145.16 199.665 142.42 194.255 142.42C188.845 142.42 184.745 145.22 182.285 150.83C179.825 156.44 178.595 166.81 178.595 181.95H178.585Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M314.895 220.01H254.045V192.54L314.895 120.2H344.015V194.1H359.105V220.02H344.015V242.49H314.895V220.02V220.01ZM314.895 194.09V156.25L282.735 194.09H314.895Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { opacity: "0.3" } }, [
          _c("path", {
            attrs: {
              d: "M144.105 77.9804C144.105 79.0104 143.265 79.8503 142.235 79.8503C141.205 79.8503 140.365 79.0104 140.365 77.9804C140.365 76.9504 141.205 76.1104 142.235 76.1104C143.265 76.1104 144.105 76.9504 144.105 77.9804Z",
              fill: "#0960BD",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M16.1149 149.81C16.8439 149.81 17.4349 149.219 17.4349 148.49C17.4349 147.761 16.8439 147.17 16.1149 147.17C15.3859 147.17 14.7949 147.761 14.7949 148.49C14.7949 149.219 15.3859 149.81 16.1149 149.81Z",
              fill: "#0960BD",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M234.204 342.581C235.248 342.581 236.094 341.735 236.094 340.691C236.094 339.647 235.248 338.801 234.204 338.801C233.161 338.801 232.314 339.647 232.314 340.691C232.314 341.735 233.161 342.581 234.204 342.581Z",
              fill: "#0960BD",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M279.655 265.679C280.384 265.679 280.975 265.088 280.975 264.359C280.975 263.63 280.384 263.039 279.655 263.039C278.926 263.039 278.335 263.63 278.335 264.359C278.335 265.088 278.926 265.679 279.655 265.679Z",
              fill: "#0960BD",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M367.325 27.9802C367.325 28.7102 366.735 29.3002 366.005 29.3002C365.275 29.3002 364.685 28.7102 364.685 27.9802C364.685 27.2502 365.275 26.6602 366.005 26.6602C366.735 26.6602 367.325 27.2502 367.325 27.9802Z",
              fill: "#0960BD",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M115.905 1.32C115.905 2.05 115.315 2.64 114.585 2.64C113.855 2.64 113.265 2.05 113.265 1.32C113.265 0.59 113.855 0 114.585 0C115.315 0 115.905 0.59 115.905 1.32Z",
              fill: "#0960BD",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M220.845 70.6205C221.574 70.6205 222.165 70.0295 222.165 69.3005C222.165 68.5715 221.574 67.9805 220.845 67.9805C220.116 67.9805 219.525 68.5715 219.525 69.3005C219.525 70.0295 220.116 70.6205 220.845 70.6205Z",
              fill: "#0960BD",
            },
          }),
        ]),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M84.3846 70.6296C96.4852 70.6296 106.295 60.8201 106.295 48.7196C106.295 36.619 96.4852 26.8096 84.3846 26.8096C72.2841 26.8096 62.4746 36.619 62.4746 48.7196C62.4746 60.8201 72.2841 70.6296 84.3846 70.6296Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.7",
            d: "M84.3846 70.6296C96.4852 70.6296 106.295 60.8201 106.295 48.7196C106.295 36.619 96.4852 26.8096 84.3846 26.8096C72.2841 26.8096 62.4746 36.619 62.4746 48.7196C62.4746 60.8201 72.2841 70.6296 84.3846 70.6296Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M76.8346 32.1895C73.7646 32.1895 70.8446 32.8195 68.1946 33.9595C64.6446 37.8495 62.4746 43.0395 62.4746 48.7195C62.4746 60.8195 72.2846 70.6195 84.3746 70.6195C87.4546 70.6195 90.3946 69.9895 93.0546 68.8395C96.5846 64.9394 98.7346 59.7695 98.7346 54.0995C98.7346 41.9895 88.9246 32.1895 76.8246 32.1895H76.8346Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M74.6543 37.9795C74.6543 39.0895 73.7543 39.9795 72.6543 39.9795C71.5543 39.9795 70.6543 39.0795 70.6543 37.9795C70.6543 36.8795 71.5543 35.9795 72.6543 35.9795C73.7543 35.9795 74.6543 36.8795 74.6543 37.9795Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M98.2148 35.9795C98.2148 37.0895 97.3148 37.9795 96.2148 37.9795C95.1148 37.9795 94.2148 37.0795 94.2148 35.9795C94.2148 34.8795 95.1148 33.9795 96.2148 33.9795C97.3148 33.9795 98.2148 34.8795 98.2148 35.9795Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M94.2143 50.2595C94.2143 52.0695 92.7443 53.5395 90.9343 53.5395C89.1243 53.5395 87.6543 52.0695 87.6543 50.2595C87.6543 48.4495 89.1243 46.9795 90.9343 46.9795C92.7443 46.9795 94.2143 48.4495 94.2143 50.2595Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M83.7952 59.6095C83.7952 62.0295 81.8352 63.9895 79.4152 63.9895C76.9952 63.9895 75.0352 62.0295 75.0352 59.6095C75.0352 57.1895 76.9952 55.2295 79.4152 55.2295C81.8352 55.2295 83.7952 57.1895 83.7952 59.6095Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M344.058 313.004C346.497 302.667 340.096 292.31 329.759 289.871C319.423 287.431 309.066 293.833 306.626 304.169C304.187 314.505 310.588 324.862 320.925 327.302C331.261 329.742 341.618 323.34 344.058 313.004Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M344.058 313.004C346.497 302.667 340.096 292.31 329.759 289.871C319.423 287.431 309.066 293.833 306.626 304.169C304.187 314.505 310.588 324.862 320.925 327.302C331.261 329.742 341.618 323.34 344.058 313.004Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.4",
            d: "M337.485 293.699C335.735 293.169 333.875 292.889 331.945 292.889C321.325 292.889 312.715 301.499 312.715 312.119C312.715 318.139 315.475 323.499 319.805 327.029C321.555 327.559 323.415 327.839 325.345 327.839C335.965 327.839 344.575 319.229 344.575 308.609C344.575 302.589 341.815 297.219 337.485 293.699Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M306.985 314.31C286.455 323.97 301.765 331.42 330.695 321.02C357.475 311.39 367.665 299.25 343.745 303.01C344.975 308.12 311.435 321.19 306.985 314.3V314.31Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M337.255 120.189C310.365 138.359 275.555 162.319 265.945 219.999H263.895C273.245 163.019 306.785 138.429 333.685 120.189H337.255Z",
            fill: "black",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M198.174 300.63C181.204 300.63 164.364 292.97 156.184 280.44C151.134 272.7 145.264 256.49 162.744 234.86L164.304 236.12C151.944 251.42 149.654 266.77 157.864 279.35C167.854 294.66 191.464 302.36 211.594 296.88C232.224 291.27 244.744 273.32 245.954 247.65C250.084 159.84 296.734 132.8 330.804 113.05C351.964 100.78 367.254 91.91 363.894 73.21C363.424 70.62 362.394 68.82 360.734 67.73C356.384 64.86 347.884 66.84 338.044 69.14C318.734 73.64 292.294 79.8 276.544 53.01L278.264 52C293.274 77.52 317.844 71.8 337.584 67.2C347.874 64.8 356.764 62.73 361.834 66.07C363.974 67.48 365.294 69.71 365.864 72.87C369.474 92.95 352.854 102.59 331.804 114.79C298.144 134.31 252.034 161.04 247.954 247.76C246.704 274.36 233.644 292.97 212.124 298.82C207.584 300.05 202.874 300.64 198.184 300.64L198.174 300.63Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M198.174 300.63C181.204 300.63 164.364 292.97 156.184 280.44C151.134 272.7 145.264 256.49 162.744 234.86L164.304 236.12C151.944 251.42 149.654 266.77 157.864 279.35C167.854 294.66 191.464 302.36 211.594 296.88C232.224 291.27 244.744 273.32 245.954 247.65C250.084 159.84 296.734 132.8 330.804 113.05C351.964 100.78 367.254 91.91 363.894 73.21C363.424 70.62 362.394 68.82 360.734 67.73C356.384 64.86 347.884 66.84 338.044 69.14C318.734 73.64 292.294 79.8 276.544 53.01L278.264 52C293.274 77.52 317.844 71.8 337.584 67.2C347.874 64.8 356.764 62.73 361.834 66.07C363.974 67.48 365.294 69.71 365.864 72.87C369.474 92.95 352.854 102.59 331.804 114.79C298.144 134.31 252.034 161.04 247.954 247.76C246.704 274.36 233.644 292.97 212.124 298.82C207.584 300.05 202.874 300.64 198.184 300.64L198.174 300.63Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M255.914 29.3093C262.774 29.1893 269.494 31.4393 269.494 31.4393C269.494 31.4393 280.454 50.2093 281.794 54.5093C281.334 58.7493 274.174 65.6993 274.174 65.6993L255.904 29.3093H255.914Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M255.914 29.3093C262.774 29.1893 269.494 31.4393 269.494 31.4393C269.494 31.4393 280.454 50.2093 281.794 54.5093C281.334 58.7493 274.174 65.6993 274.174 65.6993L255.904 29.3093H255.914Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M288.494 120.49C284.354 115.24 280.294 109.9 276.934 104.11C276.094 102.66 275.295 101.19 274.545 99.6895C274.115 98.8395 273.704 97.9795 273.304 97.1195C272.954 96.3695 272.175 95.2595 272.105 94.4395C270.905 81.7695 275.245 72.4395 271.075 62.2695L254.604 68.7095C254.604 68.7095 256.004 86.8295 259.204 97.6695C261.184 104.4 265.675 110.22 270.005 115.61C271.355 117.3 272.664 119.02 274.014 120.71C275.294 122.31 276.725 123.77 278.005 125.36C279.955 127.77 280.594 130.08 279.114 132.92C279.034 133.07 278.954 133.22 278.864 133.37C278.444 134.11 280.414 134.95 281.644 133.39C283.684 130.81 283.365 130.97 285.105 128.77C286.165 127.43 287.374 125.99 288.424 124.74C289.444 123.52 289.474 121.75 288.484 120.49H288.494Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.7",
            d: "M288.494 120.49C284.354 115.24 280.294 109.9 276.934 104.11C276.094 102.66 275.295 101.19 274.545 99.6895C274.115 98.8395 273.704 97.9795 273.304 97.1195C272.954 96.3695 272.175 95.2595 272.105 94.4395C270.905 81.7695 275.245 72.4395 271.075 62.2695L254.604 68.7095C254.604 68.7095 256.004 86.8295 259.204 97.6695C261.184 104.4 265.675 110.22 270.005 115.61C271.355 117.3 272.664 119.02 274.014 120.71C275.294 122.31 276.725 123.77 278.005 125.36C279.955 127.77 280.594 130.08 279.114 132.92C279.034 133.07 278.954 133.22 278.864 133.37C278.444 134.11 280.414 134.95 281.644 133.39C283.684 130.81 283.365 130.97 285.105 128.77C286.165 127.43 287.374 125.99 288.424 124.74C289.444 123.52 289.474 121.75 288.484 120.49H288.494Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M284.465 115.279C282.255 117.949 277.775 121.399 275.805 122.799C276.235 123.279 276.665 123.769 277.085 124.259C281.155 121.599 284.365 118.159 285.585 116.759C285.205 116.269 284.835 115.779 284.465 115.279Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M288.485 120.5C288.445 120.45 288.405 120.41 288.365 120.35C287.675 121.07 287.185 122.23 287.105 123.52C287.035 124.41 287.185 125.23 287.485 125.87C287.815 125.48 288.125 125.1 288.435 124.74C289.465 123.52 289.475 121.75 288.485 120.5Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M251.995 41.3101C250.275 43.8201 248.245 46.2901 245.615 48.5001C244.315 49.6101 242.825 50.6201 241.195 51.4801C240.375 51.9001 239.505 52.2601 238.615 52.5601L237.945 52.7801L237.725 52.8501C237.565 52.9001 237.405 52.9401 237.245 52.9801C236.945 53.0501 236.635 53.0901 236.375 53.1201C235.295 53.2001 234.505 53.0501 233.865 52.8901C232.585 52.5401 231.735 52.0801 230.925 51.6301C230.125 51.1701 229.435 50.7001 228.775 50.2201C227.475 49.2301 226.295 48.2301 225.195 47.1701C222.985 45.0601 221.025 42.8701 219.205 40.4301C218.375 39.3201 218.605 37.7501 219.715 36.9201C220.575 36.2801 221.705 36.2701 222.555 36.8101L222.635 36.8601C224.995 38.3601 227.375 39.9401 229.695 41.3501C230.865 42.0401 232.015 42.7401 233.145 43.2801C233.695 43.5601 234.245 43.8201 234.735 44.0001C235.215 44.1901 235.685 44.2901 235.815 44.2701C235.865 44.2701 235.815 44.2001 235.435 44.2101C235.345 44.2101 235.215 44.2301 235.095 44.2501C235.025 44.2601 234.945 44.2801 234.875 44.3101C234.805 44.3301 234.905 44.2901 234.905 44.2901L235.235 44.1201C235.675 43.9001 236.095 43.6201 236.525 43.3301C237.385 42.7501 238.195 42.0201 239.025 41.2101C240.675 39.5801 242.255 37.5401 243.925 35.4201L243.945 35.3901C245.655 33.2201 248.805 32.8501 250.975 34.5701C253.055 36.2101 253.465 39.1801 251.995 41.3301V41.3101Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.7",
            d: "M251.995 41.3101C250.275 43.8201 248.245 46.2901 245.615 48.5001C244.315 49.6101 242.825 50.6201 241.195 51.4801C240.375 51.9001 239.505 52.2601 238.615 52.5601L237.945 52.7801L237.725 52.8501C237.565 52.9001 237.405 52.9401 237.245 52.9801C236.945 53.0501 236.635 53.0901 236.375 53.1201C235.295 53.2001 234.505 53.0501 233.865 52.8901C232.585 52.5401 231.735 52.0801 230.925 51.6301C230.125 51.1701 229.435 50.7001 228.775 50.2201C227.475 49.2301 226.295 48.2301 225.195 47.1701C222.985 45.0601 221.025 42.8701 219.205 40.4301C218.375 39.3201 218.605 37.7501 219.715 36.9201C220.575 36.2801 221.705 36.2701 222.555 36.8101L222.635 36.8601C224.995 38.3601 227.375 39.9401 229.695 41.3501C230.865 42.0401 232.015 42.7401 233.145 43.2801C233.695 43.5601 234.245 43.8201 234.735 44.0001C235.215 44.1901 235.685 44.2901 235.815 44.2701C235.865 44.2701 235.815 44.2001 235.435 44.2101C235.345 44.2101 235.215 44.2301 235.095 44.2501C235.025 44.2601 234.945 44.2801 234.875 44.3101C234.805 44.3301 234.905 44.2901 234.905 44.2901L235.235 44.1201C235.675 43.9001 236.095 43.6201 236.525 43.3301C237.385 42.7501 238.195 42.0201 239.025 41.2101C240.675 39.5801 242.255 37.5401 243.925 35.4201L243.945 35.3901C245.655 33.2201 248.805 32.8501 250.975 34.5701C253.055 36.2101 253.465 39.1801 251.995 41.3301V41.3101Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M215.435 34.7797L216.605 36.7497C216.605 36.7497 217.505 39.3697 219.295 39.8497L224.145 38.2797L223.905 37.8697C223.285 36.9197 223.355 35.0997 223.565 33.5797C223.785 32.0597 222.995 32.0097 222.415 32.3797C222.075 32.5997 221.845 33.2897 221.565 34.0397C221.325 33.7097 221.075 33.3897 220.785 33.1097L219.305 31.6297C218.665 30.9897 217.635 30.9597 216.965 31.5597L215.765 32.6297C215.155 33.1697 215.015 34.0797 215.435 34.7797Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.7",
            d: "M215.435 34.7797L216.605 36.7497C216.605 36.7497 217.505 39.3697 219.295 39.8497L224.145 38.2797L223.905 37.8697C223.285 36.9197 223.355 35.0997 223.565 33.5797C223.785 32.0597 222.995 32.0097 222.415 32.3797C222.075 32.5997 221.845 33.2897 221.565 34.0397C221.325 33.7097 221.075 33.3897 220.785 33.1097L219.305 31.6297C218.665 30.9897 217.635 30.9597 216.965 31.5597L215.765 32.6297C215.155 33.1697 215.015 34.0797 215.435 34.7797Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M260.824 27.5801C256.174 28.7101 249.784 31.3601 245.474 34.0501C243.944 35.0001 243.184 36.8101 243.544 38.5701C245.464 48.0101 249.854 60.6501 254.594 68.7001L276.704 59.5501C276.854 55.6501 271.484 43.0301 266.024 30.8301C265.044 28.6401 263.164 27.0101 260.824 27.5701V27.5801Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.8",
            d: "M260.824 27.5801C256.174 28.7101 249.784 31.3601 245.474 34.0501C243.944 35.0001 243.184 36.8101 243.544 38.5701C245.464 48.0101 249.854 60.6501 254.594 68.7001L276.704 59.5501C276.854 55.6501 271.484 43.0301 266.024 30.8301C265.044 28.6401 263.164 27.0101 260.824 27.5701V27.5801Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M269.445 38.5696L265.055 37.0996C266.035 39.6696 269.585 42.9196 272.085 44.8296C271.265 42.8196 270.375 40.7296 269.445 38.5696Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M259.374 17.6798C257.554 14.1998 253.604 12.4498 248.854 12.8398C244.854 13.1698 241.314 17.2598 241.734 19.4598C242.154 21.6698 245.514 22.5998 246.124 23.3698L243.354 25.3798C241.974 26.3798 241.704 28.3298 242.754 29.6698C243.924 31.1498 245.454 32.6998 246.354 33.7898C254.014 33.5898 259.684 30.6698 261.724 27.8498C260.994 24.2698 261.184 21.1398 259.364 17.6698L259.374 17.6798Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.8",
            d: "M259.374 17.6798C257.554 14.1998 253.604 12.4498 248.854 12.8398C244.854 13.1698 241.314 17.2598 241.734 19.4598C242.154 21.6698 245.514 22.5998 246.124 23.3698L243.354 25.3798C241.974 26.3798 241.704 28.3298 242.754 29.6698C243.924 31.1498 245.454 32.6998 246.354 33.7898C254.014 33.5898 259.684 30.6698 261.724 27.8498C260.994 24.2698 261.184 21.1398 259.364 17.6698L259.374 17.6798Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M255.615 19.8402C257.135 23.7402 255.215 28.1302 251.315 29.6502C247.415 31.1702 243.025 29.2502 241.505 25.3502C239.985 21.4502 241.905 17.0602 245.805 15.5402C249.705 14.0202 254.095 15.9402 255.615 19.8402Z",
            fill: "#363445",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M320.545 109.96C320.435 106.67 320.295 107 320.195 104.2C320.135 102.5 320.125 100.61 320.115 98.9798C320.105 97.3898 318.975 96.0198 317.415 95.6998C316.085 95.4298 314.765 95.1798 313.435 94.8898C311.705 94.5198 310.005 94.1298 308.305 93.6298C306.995 93.2498 305.685 92.8298 304.405 92.3598C303.025 91.8598 301.665 91.3198 300.315 90.7398C298.735 90.0598 297.185 89.3398 295.635 88.5998C293.905 87.7698 292.195 86.9198 290.495 86.0498C283.915 75.1498 283.775 67.9798 276.705 59.5498L261.545 66.4098C261.545 66.4098 272.685 86.1698 280.265 94.5498C284.635 99.3698 291.485 101.55 297.595 103.12C302.005 104.26 306.475 105.13 310.945 105.95C312.685 106.27 314.575 106.39 316.075 107.43C317.255 108.25 317.865 109.53 318.215 110.88C318.285 111.16 318.345 111.44 318.395 111.72C318.555 112.56 320.595 111.93 320.525 109.94L320.545 109.96Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.8",
            d: "M320.545 109.96C320.435 106.67 320.295 107 320.195 104.2C320.135 102.5 320.125 100.61 320.115 98.9798C320.105 97.3898 318.975 96.0198 317.415 95.6998C316.085 95.4298 314.765 95.1798 313.435 94.8898C311.705 94.5198 310.005 94.1298 308.305 93.6298C306.995 93.2498 305.685 92.8298 304.405 92.3598C303.025 91.8598 301.665 91.3198 300.315 90.7398C298.735 90.0598 297.185 89.3398 295.635 88.5998C293.905 87.7698 292.195 86.9198 290.495 86.0498C283.915 75.1498 283.775 67.9798 276.705 59.5498L261.545 66.4098C261.545 66.4098 272.685 86.1698 280.265 94.5498C284.635 99.3698 291.485 101.55 297.595 103.12C302.005 104.26 306.475 105.13 310.945 105.95C312.685 106.27 314.575 106.39 316.075 107.43C317.255 108.25 317.865 109.53 318.215 110.88C318.285 111.16 318.345 111.44 318.395 111.72C318.555 112.56 320.595 111.93 320.525 109.94L320.545 109.96Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M312.855 94.7596C312.255 94.6296 311.655 94.4996 311.045 94.3496C311.095 97.7996 309.475 103.77 308.875 105.57C309.505 105.69 310.145 105.81 310.775 105.93C312.535 101.18 312.805 96.1596 312.845 94.7496L312.855 94.7596Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M320.115 98.97C320.105 97.37 318.985 96.01 317.425 95.69C317.115 95.63 316.785 95.56 316.475 95.5C316.545 96.44 317.125 97.53 318.105 98.4C318.745 98.98 319.475 99.36 320.135 99.54C320.135 99.34 320.135 99.15 320.125 98.97H320.115Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M254.194 19.8997C254.594 21.4197 252.904 23.0097 251.544 21.6997C250.444 20.6397 248.784 18.8497 247.424 18.0097C246.034 17.1397 247.884 15.6197 250.074 16.2097C252.114 16.7597 253.784 18.3897 254.194 19.8997Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M254.315 68.2199C253.615 68.4799 254.895 69.6799 254.895 69.6799C254.895 69.6799 268.865 64.8899 277.385 59.9599C277.455 58.9299 276.705 58.3799 276.705 58.3799C276.705 58.3799 266.335 63.7799 254.305 68.2199H254.315Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M254.315 68.2199C253.615 68.4799 254.895 69.6799 254.895 69.6799C254.895 69.6799 268.865 64.8899 277.385 59.9599C277.455 58.9299 276.705 58.3799 276.705 58.3799C276.705 58.3799 266.335 63.7799 254.305 68.2199H254.315Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M264.615 26.9197C267.375 28.3097 269.965 29.7897 272.565 31.4197C273.855 32.2297 275.145 33.0697 276.405 33.9697C277.685 34.8497 278.935 35.7797 280.205 36.8297L280.675 37.2197L281.265 37.7497C281.685 38.1197 281.995 38.4597 282.305 38.7997C282.625 39.1497 282.895 39.4897 283.155 39.8297C283.425 40.1697 283.685 40.5097 283.925 40.8397C284.915 42.1697 285.725 43.5197 286.515 44.8597C288.055 47.5797 289.385 50.2897 290.485 53.2097C290.975 54.5097 290.315 55.9597 289.015 56.4497C288.035 56.8197 286.975 56.5397 286.295 55.8197L286.245 55.7697C284.235 53.6397 282.305 51.3997 280.365 49.3097C278.465 47.2297 276.455 45.0997 274.825 44.1697C272.555 42.7497 270.025 41.3497 267.515 39.9597L259.955 35.7597H259.935C257.515 34.4097 256.645 31.3697 257.985 28.9497C259.295 26.5997 262.225 25.7097 264.615 26.9097V26.9197Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.8",
            d: "M264.615 26.9197C267.375 28.3097 269.965 29.7897 272.565 31.4197C273.855 32.2297 275.145 33.0697 276.405 33.9697C277.685 34.8497 278.935 35.7797 280.205 36.8297L280.675 37.2197L281.265 37.7497C281.685 38.1197 281.995 38.4597 282.305 38.7997C282.625 39.1497 282.895 39.4897 283.155 39.8297C283.425 40.1697 283.685 40.5097 283.925 40.8397C284.915 42.1697 285.725 43.5197 286.515 44.8597C288.055 47.5797 289.385 50.2897 290.485 53.2097C290.975 54.5097 290.315 55.9597 289.015 56.4497C288.035 56.8197 286.975 56.5397 286.295 55.8197L286.245 55.7697C284.235 53.6397 282.305 51.3997 280.365 49.3097C278.465 47.2297 276.455 45.0997 274.825 44.1697C272.555 42.7497 270.025 41.3497 267.515 39.9597L259.955 35.7597H259.935C257.515 34.4097 256.645 31.3697 257.985 28.9497C259.295 26.5997 262.225 25.7097 264.615 26.9097V26.9197Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M292.885 58.0993L292.035 55.9693C292.035 55.9693 291.565 53.2493 289.865 52.4893L284.825 53.2793L285.005 53.7193C285.475 54.7493 285.115 56.5493 284.665 58.0093C284.215 59.4793 284.985 59.6493 285.615 59.3693C285.985 59.2093 286.325 58.5593 286.715 57.8593C286.895 58.2193 287.105 58.5793 287.345 58.9093L288.575 60.5993C289.105 61.3293 290.115 61.5193 290.875 61.0293L292.225 60.1593C292.915 59.7193 293.195 58.8493 292.895 58.0893L292.885 58.0993Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.8",
            d: "M292.885 58.0993L292.035 55.9693C292.035 55.9693 291.565 53.2493 289.865 52.4893L284.825 53.2793L285.005 53.7193C285.475 54.7493 285.115 56.5493 284.665 58.0093C284.215 59.4793 284.985 59.6493 285.615 59.3693C285.985 59.2093 286.325 58.5593 286.715 57.8593C286.895 58.2193 287.105 58.5793 287.345 58.9093L288.575 60.5993C289.105 61.3293 290.115 61.5193 290.875 61.0293L292.225 60.1593C292.915 59.7193 293.195 58.8493 292.895 58.0893L292.885 58.0993Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M260.385 38.4199L259.165 38.5199L251.675 56.5999C252.115 56.6299 252.515 56.5999 252.895 56.4999C252.895 56.4999 262.655 52.8599 265.605 51.5299C263.615 47.4099 260.375 38.4199 260.375 38.4199H260.385Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M246.725 43.1595C247.145 46.4195 249.585 53.3395 251.685 56.5995C255.345 55.3395 261.445 52.9595 264.395 51.6295C262.405 47.5095 260.315 41.8295 259.165 38.5195C256.015 38.7295 249.155 41.3195 246.715 43.1695L246.725 43.1595Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M254.155 47.0703C253.745 48.4303 252.305 49.2103 250.945 48.8003C249.585 48.3903 248.805 46.9503 249.215 45.5903C249.625 44.2303 251.065 43.4503 252.425 43.8603C253.785 44.2703 254.565 45.7103 254.155 47.0703Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.6",
            d: "M256.064 43.6302C255.934 44.0802 255.464 44.3302 255.014 44.2002C254.564 44.0702 254.314 43.6002 254.444 43.1502C254.574 42.7002 255.044 42.4502 255.494 42.5802C255.944 42.7102 256.194 43.1802 256.064 43.6302Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.6",
            d: "M258.295 42.7601C258.165 43.2101 257.695 43.4601 257.245 43.3301C256.795 43.2001 256.545 42.7301 256.675 42.2801C256.805 41.8301 257.285 41.5801 257.725 41.7101C258.175 41.8401 258.425 42.3101 258.295 42.7601Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.5",
            d: "M261.445 50.5495L252.195 54.1995L251.635 52.3795L260.885 48.7295L261.445 50.5495Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M210.415 189.53C210.215 197.07 209.655 202.86 208.735 206.88C207.555 212.02 205.725 215.78 203.235 218.16C200.755 220.54 197.815 221.73 194.425 221.73C189.285 221.73 185.355 218.93 182.655 213.32C182.215 212.41 181.815 211.37 181.445 210.21C175.735 215.12 170.415 220.48 165.525 226.24L160.215 232.5C163.085 235.41 166.345 237.8 169.985 239.69C176.215 242.91 184.385 244.53 194.505 244.53C196.285 244.53 198.005 244.48 199.675 244.38C205.795 235.32 210.865 225.58 214.775 215.36L229.625 176.64L210.415 189.53Z",
            fill: "black",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M76.5449 242.321C82.1449 247.921 94.0449 259.821 94.0449 259.821L143.055 213.651C126.185 207.241 93.3149 225.551 76.5449 242.321Z",
            fill: "#363445",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M137.575 303.35C131.975 297.75 120.075 285.85 120.075 285.85L166.245 236.84C172.655 253.71 154.345 286.58 137.575 303.35Z",
            fill: "#363445",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M204.555 193.06L223.645 156.25L186.835 175.34C175.005 181.47 164.095 189.22 154.395 198.35L91.6445 257.42L122.475 288.25L181.545 225.5C190.675 215.8 198.415 204.88 204.555 193.06Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.6",
            d: "M204.555 193.06L223.645 156.25L186.835 175.34C175.005 181.47 164.095 189.22 154.395 198.35L91.6445 257.42L122.475 288.25L181.545 225.5C190.675 215.8 198.415 204.88 204.555 193.06Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M165.355 227.441C172.48 227.441 178.255 221.665 178.255 214.541C178.255 207.416 172.48 201.641 165.355 201.641C158.231 201.641 152.455 207.416 152.455 214.541C152.455 221.665 158.231 227.441 165.355 227.441Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M165.354 222.83C169.933 222.83 173.644 219.118 173.644 214.54C173.644 209.962 169.933 206.25 165.354 206.25C160.776 206.25 157.064 209.962 157.064 214.54C157.064 219.118 160.776 222.83 165.354 222.83Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.3",
            d: "M132.895 277.18L102.715 247L127.425 223.73L156.165 252.47L132.895 277.18Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M83.3747 269.98C60.7747 271.82 53.2847 286.29 50.7247 305.52C49.4247 315.33 48.8447 325.25 40.6047 331C38.3147 332.59 39.4547 336.15 42.2347 336.06C72.5747 335.11 86.7247 320.26 88.5047 314.04C87.8947 317.97 87.0047 321.08 86.0147 323.51C84.9947 326.02 87.7547 328.34 90.0447 326.9C98.5647 321.56 109.245 311.74 109.955 295.82C103.655 286.97 83.3747 269.98 83.3747 269.98Z",
            fill: "#0960BD",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            opacity: "0.2",
            d: "M83.3747 269.98C60.7747 271.82 53.2847 286.29 50.7247 305.52C49.4247 315.33 48.8447 325.25 40.6047 331C38.3147 332.59 39.4547 336.15 42.2347 336.06C72.5747 335.11 86.7247 320.26 88.5047 314.04C87.8947 317.97 87.0047 321.08 86.0147 323.51C84.9947 326.02 87.7547 328.34 90.0447 326.9C98.5647 321.56 109.245 311.74 109.955 295.82C103.655 286.97 83.3747 269.98 83.3747 269.98Z",
            fill: "white",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M113.424 302.659L77.2344 266.459L96.5144 262.289L117.604 283.379L113.424 302.659Z",
            fill: "#0960BD",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_10765_97988" } }, [
          _c("rect", {
            attrs: {
              width: "384.19",
              height: "359.88",
              fill: "white",
              transform: "translate(0.904297)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }